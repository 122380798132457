import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

// import firebase from 'firebase';

import titleMixin from '@/utils/titleMixin';

const ap = createApp(App)

ap.use(store)
ap.use(router)

ap.mixin(titleMixin)

// ap.config.compilerOptions.isCustomElement = (tag) => {
//     return tag.startsWith('animatetransform') || tag.startsWith('saat') // (return true)
// }

// const firebaseConfig = {
//     apiKey: "AIzaSyBJlxAypnyl1jjr5POB70KWsynrJdcxW9A",
//     authDomain: "pixonanimation-843cd.firebaseapp.com",
//     projectId: "pixonanimation-843cd",
//     storageBucket: "pixonanimation-843cd.appspot.com",
//     messagingSenderId: "141588396355",
//     appId: "1:141588396355:web:d7b5a1f808ee112a9fd7f6"
// };

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);


ap.config.globalProperties.$filters = {
    formatDate: value => {
        let d = value.split('-');
        // let m = new Date(0,d[1],0).toLocaleDateString(store.state.lang, {month: 'short'});
        return `${d[2]}.${d[1]}.${d[0]}`;
        // new Date(value).toLocaleDateString(store.state.lang, {
        //     year: 'numeric',
        //     month: 'short',
        //     day: 'numeric'
        // }),
    },

    replace: (str, txt1, txt2) => {
        let re = RegExp(txt1, "img");
        return str.replace(re, txt2);
    },

    capitalize: str => str != '' ? (str.charAt(0).toUpperCase() + str.slice(1)) : '',

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',

    formatCurrency: (value) => {
        if (value)
            return value.toLocaleString("az-AZ", {
                style: "currency",
                currency: "AZN",
            });
        return value;
    },

}


// ap.config.errorHandler = (err, instance, info) => {
//     return false;
//     // handle error, e.g. report to a service
// }




ap.mount('#app')
