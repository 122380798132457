import { createStore } from 'vuex'

export default createStore({
    state: () => {
        return {
            lang: 'az',
            translations: {},
            sidebarStatus: false,
            token: "",
            api: {
                login:                  '/login',
                translations:           '/lang/{lang}/translations',


                category:                       '/category/{lang}/',
                category_update:                '/category/{lang}/{category_id}',
            }
        }
    },
    getters: {
        getSidebarStatus: (state) => () => {
            return state.sidebarStatus;
        },
        setSidebarStatus: (state) => (value) => {
            bootstrap.Dropdown.clearMenus();
            return state.sidebarStatus = value; //.find(todo => todo.id === id)
        },

        getLang: (state) => () => {
            return state.lang;
        },
        getTranslations: (state) => () => {
            return state.translations;
        },

        // ---------------------------------------------------------------------------------------------
        getLoginLink: (state) => () => {
            return state.api.login;
        },
        getTranslationsLink: (state) => () => {
            return state.api.translations.replace('{lang}', state.lang);
        },



        // ---------------------------------------------------------------------------------------------
        getCategoryLink: (state) => () => {
            return state.api.category.replace('{lang}', state.lang);
        },
        getCategoryUpdateLink: (state) => (category_id) => {
            return state.api.category_update.replace('{lang}', state.lang).replace('{category_id}', category_id);
        },


    },
    mutations: {
        setLang(state, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            state.lang = lang;
        },
        setTranslations(state, tr) {
            if (tr !== undefined)
                state.translations = tr;
        },
    },
    actions: {
        setLang({ commit }, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            commit('setLang', lang);
        },
        setTranslations({ commit }, tr) {
            if (tr !== undefined)
                commit('setTranslations', tr);
        },
    },
    modules: {
    }
})
