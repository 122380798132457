import { createRouter, createWebHistory } from 'vue-router'

import { G } from "@/_common";
// import moment from 'moment'
// import NProgress from 'nprogress';

const routes = [
    {
        path: '/',
        name: 'meetings',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/Home" } },
        // props: { data: { page: "Agenda", data: {depId: 1} } },
        // meta: { requireAuth: true },
    },

    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        component: () => import('@/layouts/404.vue'),
        // meta: { requireAuth: true },
    },
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(),
    history: createWebHistory(),
    base: '',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash }
        }
        return { left: 0, top: 0 }
    }
});


export default router
