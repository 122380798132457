<template>

<div>

    <router-view :key="$route.path" />

</div>

</template>

<script>
    import "@/assets/css/root.css";
    import "@/assets/css/style.css";

    import { ref, onMounted } from "vue";
    import { useStore } from "vuex";
    import { useRoute } from "vue-router";

    import { G } from "@/_common";

    export default {
        name: "App",
        components: {
        },
        setup() {
            const store = useStore();
            const route = useRoute();

            G.currentRoute = route;

            onMounted(async() => {

                const def_lang = route.params && route.params.lang ? route.params.lang : "az";

                store.commit("setLang", def_lang);
            });

            return { G };
        },
    };
</script>

<style lang="scss">
    // $mm-color: #661c0a;
    $mm-color: #0e4575;

    .fa,
    .far,
    .fas {
        font-family: "Font Awesome 5 Free" !important;
    }
</style>
