import { reactive, ref } from "vue"
// import axios from 'axios'
// import NProgress from 'nprogress';
// import moment from 'moment';
// import router from "@/router";


export const G = {
    // ajaxBaseUrl: `http://localhost:8000`,
    // ajaxBaseUrl: `http://172.30.51.203:8000`,

    mediaURL: `http://localhost:8000/media/`,

    main_host: 'pixonanimation.az',
    csrfName: "",
    csrfHash: "",
    token: "",
    currentRoute: "",
    current: reactive({
        page: "",
        data: {}
    }),
    columnMaxConstraints: 3,

    defaultPage: "pages/Home",

    projectName: "pixonanimation.az",
    footerPowered: 'Powered By <a href="https://pixonanimation.az" class="text-info" target="_blank">pixonanimation.az</a> &copy; 2022',
    footerDescription: '',

    rowsPerPageOptions: [5, 10, 25, 50, 100, 500],

    modal: reactive({
        page: null,
        data: null
    }),


    uniqueId: () => {
        if (arguments[0]) var symlen = arguments[0];
        else var symlen = 4;
        var symsize, pos, id = '', sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        symsize = sym.length;
        for (var i = 1; i <= symlen; i++) {
            pos = Math.floor(Math.random() * symsize);
            id += sym[pos];
        }
        return id;
    },

    showModal: (opt) => {
        G.modal.page = opt.page;
        G.modal.data = opt;
    },
    closeModal: () => {
        G.modal.page = null;
        G.modal.data = null;
    },

    modalFormValidate: (frm) => {
        let a = frm.currentTarget.closest('.dynamicModal').querySelector('form').querySelectorAll('.required');
        a.forEach((b) => {
            if (b.value == '') {
                b.classList.add('required');
            }
        });
    },

    // capitalize: (txt) => {
    //     return txt
    //         .trim()
    //         .toLowerCase()
    //         .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    // },
    capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',

    strip: (str, sym) => str != '' ? str.replace(new RegExp(`^${sym}+`, 'i'), '').replace(new RegExp(`${sym}+$`, 'i'), '') : '',

    token_data: () => {
        if (G.hasClientData("AUTH_TOKEN"))
            return JSON.parse(atob(G.getClientData('AUTH_TOKEN').split('.')[0]));
        else
            return {};
    },

    formatLocale: (value) => {
        if (value)
            return value.toLocaleString("az-AZ", {
                style: "currency",
                currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    formatDateLocale: (value) => {
        if (value)
            return value.toLocaleDateString("az-AZ", {
                // style: "currency",
                // currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    getErrorMessages: (data) => {
        let m = [];
        if (typeof data === 'object')
            data.forEach((s) =>
                Object.keys(s).map((k) => { if (k != 'type') m.push(`${k} : ${s[k]}`); })
            )
        else m[0] = data;

        return m.join("<br />");
    },


    hasClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) return true;
        else return false;
    },
    setClientData: async (name, value) => {
        if (typeof (Storage) !== 'undefined') {
            localStorage.setItem(name, value);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    getClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            return localStorage.getItem(name);
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    removeClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            localStorage.removeItem(name);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    is_mobile: () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return true;//"Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return true;//"Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;//"iOS";
        }

        return false;
    },
}
